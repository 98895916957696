import i18n from "@/i18n";

export function getFormatInSelectFacilityStatus(status) {
    switch (status) {
        case 'Open': return {'label':i18n.t('facilities.status.open'),'value':'open'}
        case 'Closed': return {'label':i18n.t('facilities.status.closed'),'value':'closed'}
        case 'In preparation': return {'label':i18n.t('facilities.status.inporeparation'),'value':'in-preparation'}
        case 'Partially Open': return {'label':i18n.t('facilities.status.partiallyopen'),'value':'partially-open'}
    }
}

export function getFormatInSelectFacilityGroomed(groomed, groomed_am, groomed_pm) {
    if (groomed_am == 1) {
        return {'label':i18n.t('facilities.groomed.groomedam'),'value':'groomed-am'}
    } else if(groomed_pm == 1) {
        return {'label':i18n.t('facilities.groomed.groomedpm'),'value':'groomed-pm'}

    } else if(groomed == 'Groomed') {
        return {'label':i18n.t('facilities.groomed.groomed'),'value':'groomed'}
    } else {
        return {'label':i18n.t('facilities.groomed.notgroomed'),'value':'not-groomed'}
    }
}

export function getFacilityStatus() {
    return [
        {'label':i18n.t('facilities.status.open'),'value':'open'},
        {'label':i18n.t('facilities.status.closed'),'value':'closed'},
        {'label':i18n.t('facilities.status.inporeparation'),'value':'in-preparation'},
        {'label':i18n.t('facilities.status.partiallyopen'),'value':'partially-open'}
    ]
}

export function getParkingType() {
    return [
        {'label':i18n.t('facilities.parking.type_free'),'value':'free'},
        {'label':i18n.t('facilities.parking.type_paid'),'value':'paid'},
    ]
}
export function getParkingState() {
    return [
        {'label':i18n.t('facilities.parking.status_free'),'value':'free'},
        {'label':i18n.t('facilities.parking.status_closed'),'value':'closed'},
    ]
}

export function getRoadOptions() {
    return [
        {'label':i18n.t('facilities.road.state_open'),'value':'open'},
        {'label':i18n.t('facilities.road.state_equipment'),'value':'equipment'},
        {'label':i18n.t('facilities.road.state_closed'),'value':'closed'},
    ]
}

export function getFacilityGroomed() {
    return [
        {'label':i18n.t('facilities.groomed.groomed'),'value':'groomed'},
        {'label':i18n.t('facilities.groomed.notgroomed'),'value':'not-groomed'},
        {'label':i18n.t('facilities.groomed.groomedam'),'value':'groomed-am'},
        {'label':i18n.t('facilities.groomed.groomedpm'),'value':'groomed-pm'},
    ]
}

export function getFacilityTimeOfYear() {
    return [
        {'label':i18n.t('always'),'value':'always'},
        {'label':i18n.t('winter'),'value':'winter'},
        {'label':i18n.t('summer'),'value':'summer'}
    ]
}

export function getFormatInSelectFacilityTimeOfYear(timeofyear) {
    switch (timeofyear) {
        case 'always': return {'label':i18n.t('always'),'value':'always'}
        case 'winter': return {'label':i18n.t('winter'),'value':'winter'}
        case 'summer': return {'label':i18n.t('summer'),'value':'summer'}
    }
}
export function getFacilityLiftType() {
    return [
        {'label':i18n.t('facilities.type.magiccarpet'),'value':'magic_carpet', 'icon':require("@/assets/images/icons/poi/magic_carpet.png")},
        {'label':i18n.t('facilities.type.chairlift'),'value':'chair_lift', 'icon':require("@/assets/images/icons/poi/chair_lift.png")},
        {'label':i18n.t('facilities.type.gondola'),'value':'gondola', 'icon':require("@/assets/images/icons/poi/gondola.png")},
        {'label':i18n.t('facilities.type.tbar'),'value':'t-bar', 'icon':require("@/assets/images/icons/poi/t-bar.png")},
        {'label':i18n.t('facilities.type.ropetow'),'value':'rope_tow', 'icon':require("@/assets/images/icons/poi/rope_tow.png")},
        {'label':i18n.t('facilities.type.lift'),'value':'lift', 'icon':require("@/assets/images/icons/poi/lift.png")},
        {'label':i18n.t('facilities.type.cablecar'),'value':'cable_car', 'icon':require("@/assets/images/icons/poi/cable_car.png")},
    ]
}

export function getFacilityDifficulty(reference) {
    switch (reference) {
        case 'usa_style' :
            return [
                {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/green_circle.png")},
                {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")},
                {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")},
                {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")},
                {'label':i18n.t('facilities.difficulty.veryhard'), 'value': 'advanced', 'icon': require("@/assets/images/icons/slopedifficulty/double_black_diamond.png") }                                ,
                {'label':i18n.t('facilities.difficulty.expert'), 'value': 'expert', 'icon': require("@/assets/images/icons/slopedifficulty/triple_black_diamond.png") },
                {'label':i18n.t('facilities.difficulty.freeride'), 'value': 'freeride', 'icon': require("@/assets/images/icons/slopedifficulty/freeride.png") },
            ]
        case 'europe_style' :
            return [              
                {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/green_circle.png")},
                {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")},
                {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")},
                {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/red_square.png")},
                {'label':i18n.t('facilities.difficulty.veryhard'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")},
                {'label':i18n.t('facilities.difficulty.expert'), 'value':'expert', 'icon':require("@/assets/images/icons/slopedifficulty/double_black_diamond.png")},
                {'label':i18n.t('facilities.difficulty.freeride'), 'value':'freeride', 'icon':require("@/assets/images/icons/slopedifficulty/freeride.png")},
            ]
        case 'international_style' :
            return [               
                {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")},
                {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/red_square.png")},
                {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")},
                {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")},
                {'label':i18n.t('facilities.difficulty.veryhard'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_black_diamond.png")},
                {'label':i18n.t('facilities.difficulty.expert'), 'value': 'expert', 'icon': require("@/assets/images/icons/slopedifficulty/triple_black_diamond.png") },
                {'label':i18n.t('facilities.difficulty.freeride'), 'value':'freeride', 'icon':require("@/assets/images/icons/slopedifficulty/freeride.png")},
            ]
    }
}

export function getFormatInSelectFacilityDifficulty(difficulty, reference) {
    switch (reference) {
        case 'usa_style':
            switch(difficulty) {
                case 'Green circle':
                    return {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/green_circle.png")}
                case  'Blue square' :
                    return {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")}
                case  'Black diamond' :
                    return {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")}
                case  'Double blue square' :
                    return {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")}
                case  'Double black diamond' :
                    return { 'label': i18n.t('facilities.difficulty.veryhard'), 'value': 'advanced', 'icon': require("@/assets/images/icons/slopedifficulty/double_black_diamond.png") }                                
                case  'Triple black diamond' :
                    return { 'label': i18n.t('facilities.difficulty.expert'), 'value': 'advanced', 'icon': require("@/assets/images/icons/slopedifficulty/triple_black_diamond.png") }
                case  'Freeride' :
                    return { 'label': i18n.t('facilities.difficulty.freeride'), 'value': 'advanced', 'icon': require("@/assets/images/icons/slopedifficulty/freeride.png") }
                default:
                    return {'label':'', 'value':'not-defined', 'icon':''}
            }
        case 'europe_style':
            switch (difficulty) {                
                case  'Green' :
                    return {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/green_circle.png")}
                case  'Blue' :
                    return {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")}
                case  'Red' :
                    return {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/red_square.png")}
                case  'Black' :
                    return {'label':i18n.t('facilities.difficulty.veryhard'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")}
                case  'Double black diamond' :
                    return {'label':i18n.t('facilities.difficulty.expert'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_black_diamond.png")}
                case  'Freeride' :
                    return {'label':i18n.t('facilities.difficulty.freeride'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/freeride.png")}
                case  'Double blue square' :
                    return {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")}
                default:
                    return {'label':'', 'value':'not-defined', 'icon':''}
                }
        case 'international_style':
            switch(difficulty) {
                case 'Blue':
                    return {'label':i18n.t('facilities.difficulty.easy'), 'value':'novice', 'icon':require("@/assets/images/icons/slopedifficulty/blue_square.png")}
                case  'Red' :
                    return {'label':i18n.t('facilities.difficulty.intermediate'), 'value':'easy', 'icon':require("@/assets/images/icons/slopedifficulty/red_square.png")}
                case  'Black' :
                    return {'label':i18n.t('facilities.difficulty.hard'), 'value':'intermediate', 'icon':require("@/assets/images/icons/slopedifficulty/black_diamond.png")}
                case  'Double black diamond' :
                    return {'label':i18n.t('facilities.difficulty.veryhard'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_black_diamond.png")}
                case  'Triple black diamond' :
                    return { 'label': i18n.t('facilities.difficulty.expert'), 'value': 'advanced', 'icon': require("@/assets/images/icons/slopedifficulty/triple_black_diamond.png") }
                case  'Freeride' :
                    return {'label':i18n.t('facilities.difficulty.freeride'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/freeride.png")}
                case  'Double blue square' :
                    return {'label':i18n.t('facilities.difficulty.intermediate-advanced'), 'value':'advanced', 'icon':require("@/assets/images/icons/slopedifficulty/double_blue_square.png")}
                default:
                    return {'label':'', 'value':'not-defined', 'icon':''}
            }
        default:
            return {'label':'', 'value':'not-defined', 'icon':''}
    }
}
export function getFormatInSelectFacilityLiftType(type) {
    switch(type) {
        case 'magic_carpet': return  {'label':i18n.t('facilities.type.magiccarpet'),'value':'magic_carpet', 'icon':require("@/assets/images/icons/poi/magic_carpet.png")}
        case 'chair_lift': return  {'label':i18n.t('facilities.type.chairlift'),'value':'chair_lift', 'icon':require("@/assets/images/icons/poi/chair_lift.png")}
        case 'gondola': return  {'label':i18n.t('facilities.type.gondola'),'value':'gondola', 'icon':require("@/assets/images/icons/poi/gondola.png")}
        case 't-bar': return  {'label':i18n.t('facilities.type.tbar'),'value':'t-bar', 'icon':require("@/assets/images/icons/poi/t-bar.png")}
        case 'rope_tow': return  {'label':i18n.t('facilities.type.ropetow'),'value':'rope_tow', 'icon':require("@/assets/images/icons/poi/rope_tow.png")}
        case 'lift': return  {'label':i18n.t('facilities.type.lift'),'value':'lift', 'icon':require("@/assets/images/icons/poi/lift.png")}
        case 'cable_car': return  {'label':i18n.t('facilities.type.cablecar'),'value':'cable_car', 'icon':require("@/assets/images/icons/poi/cable_car.png")}
    }
}